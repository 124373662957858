// productList.productsInSubscription.controller.js
(function() {
    'use strict';

    angular
        .module('app.controllers')
        .controller('ProductListProductsInSubscriptionController', ProductListProductsInSubscriptionController);

    // Inject dependencies.
    ProductListProductsInSubscriptionController.$inject = [
        '$scope',
        'InstrumentService',
        'ProductFilterService',
        'ProfilerService',
        'TableConfigService',
        'MediaQueryService',
        '$q',
        '$timeout',
        'HistoryService'
    ];

    function ProductListProductsInSubscriptionController(
        vm,
        InstrumentService,
        ProductFilterService,
        ProfilerService,
        TableConfigService,
        MediaQueryService,
        $q,
        $timeout,
        HistoryService
    ) {
        vm.media = MediaQueryService.getMedia();

        var container = '.products-in-subscription',
            url = '/subscription-products';

        // Update tabs navigation when enter controller 
        $timeout(vm.$parent.updateNavigation);

        // Get groups.
        $(container).addClass('icon-loading');

        if (HistoryService.isCurrentlyAllowedToRestore && HistoryService.load() && HistoryService.restoreData()) {
            handleSuccessResponse(HistoryService.restoreData(), 'ignore');
            handleFinallyCallback();
        } else {
            InstrumentService.getGroups(url).then(handleSuccessResponse, function() {
                vm.groups = [];
            }).finally(handleFinallyCallback);
        }

        function handleSuccessResponse(data, ignore) {
            vm.groups = data.groups;

            // Start restoring previous navigation state
            HistoryService.restore(vm.groups);

            // Store data for back button cache
            if (ignore !== 'ignore') {
                HistoryService.storeData(data);
            }
        }

        function handleFinallyCallback() {
            $timeout(function() {
                ProfilerService.setTimeLoad();
                vm.showGroupNavigation = true;
                $(container).removeClass('icon-loading');

                //Hide label prices indicative
                $('.label-prices-indicative').hide();
            }, 100);
        }


        /**
         * Assign table template to instrument groups
         * @param {string} groupId - Instrument group id.
         */
        vm.assignTableTemplate = function(groupId) {
            return TableConfigService.get(groupId);
        };

        // Get instrument documents
        vm.getDocuments = function(event, instrument) {
            event.stopPropagation();

            var container = event.target;

            //Set flyout top position
            function setPosition() {
                $('flyout-documents').css('top', $(event.target).position().top + 40);
                vm.showFlyOutDocuments = 'downloads';
                vm.flyOutDocumentsIdentifier = instrument.valor.value; // Set valor for product detail link
            }

            // Select flyout or modal regarding the current mediaquery
            function showFlyout() {

                // Set button active state
                $(container).addClass('active');

                // Choose flyout or modalbox for mobile devices
                if (vm.media === 'mobile' || vm.media === 'medium') {
                    vm.showModal = true;
                    vm.modalDocumentsIdentifier = instrument.valor.value; // Set valor for product detail link
                } else {
                    setPosition();
                }
            }

            //Show flyout if document data is already added
            if (instrument.documents) {
                showFlyout();
                return;
            }

            // Get data using service
            $(container).addClass('loading');
            InstrumentService.getDocuments(instrument.sin.value).then(function(data) {
                vm.flyoutInstrument = data;
            }).finally(function() {
                if (instrument.isKidAvailable.value) {
                    vm.flyoutInstrument.kid = InstrumentService.getKidDocuments(instrument.isin.value);
                }

                showFlyout();
                $(container).removeClass('loading');
            });
        };

        /**
         * Sort group by calling backend api (to be called from instrument-table directive)
         */
        vm.sortGroup = function(group) {

            // Paging not supported here
            getInstrumentGroupListData(group);

        };

        /**
         * Get Instrumentgroup list data
         * @param {object} group - Instrumentgroup.
         */
        function getInstrumentGroupListData(group, merge) {

            var additionalParams,
                deferred = $q.defer();

            // Get products
            $(container + ' [data-id]').addClass('icon-loading');

            // Add additional params for list query (sorting)
            if (group.sorting) {
                additionalParams = {
                    sort: group.sorting
                };
            }

            // by default couponPercentPa should be descending for each sort selection by user  #68702
            if (!additionalParams.sort.couponPercentPa) {
                additionalParams.sort.couponPercentPa = -1;
            }

            var params = decodeURIComponent($.param(additionalParams));

            // Get instruments
            InstrumentService.getProducts(
                group,
                url,
                params
            ).then(function(data) {

                handleSuccess(data, merge);

            }, function(err) {
                console.log(err);
                deferred.reject();
            }).finally(function() {
                deferred.resolve();

                // Remove loader.
                $(container + ' [data-id]').removeClass('icon-loading');
            });

            function handleSuccess(data, merge) {

                //Merge old with new table data
                if (merge) {
                    Array.prototype.push.apply(group.rows, data.rows);
                } else { //Overwrite
                    angular.extend(group, data);
                }
            }

            return deferred.promise;
        }

        /**
         * Apply local instrument table sort to all groups
         * It will be called from the instrumentTable directive
         * @param sortKey
         * @param direction
         */
        vm.applySortForAllGroups = function(sortKey, direction) {
            try {
                if (vm.groups && vm.groups.length && vm.groups.length > 0) {
                    for (var i = 0; i < vm.groups.length; i++) {
                        vm.groups[i].sorting = {};
                        vm.groups[i].sorting[sortKey] = direction;
                        vm.groups[i].sortingKey = sortKey;
                        vm.groups[i].sortingIndex = direction;
                    }
                }

                vm.$apply();

            } catch (e) {
                console.log(e);
            }
        };
    }
})();
// tableConfig.service.js
(function() {
    'use strict';

    angular
        .module('app.services')
        .factory('TableConfigService', TableConfigService);

    // Inject dependencies.
    TableConfigService.$inject = [
        'AutocallableBarrierReverseConvertible',
        'AutocallableMultiBarrierReverseConvertible',
        'AutocallableMultiReverseConvertible',
        'AutocallableMultiReverseConvertibleWithConditionalCoupon',
        'AutocallableReverseConvertible',
        'AutocallableReverseConvertibleWithConditionalCoupon',
        'AutocallableBarrierReverseConvertibleWithConditionalMemoryCoupon',
        'AutocallableMultiBarrierReverseConvertibleWithConditionalMemoryCoupon',
        'AutocallableBarrierReverseConvertibleWithConditionalCoupon',
        'AutocallableMultiBarrierReverseConvertibleWithConditionalCoupon',
        'AutocallableReverseConvertibleWithConditionalMemoryCoupon',
        'AutocallableMultiReverseConvertibleWithConditionalMemoryCoupon',
        'BarrierReverseConvertible',
        'BonusCertificate',
        'CapitalProtectionWithParticipation',
        'CapitalProtectionWithParticipationWorstOf',
        'DiscountCertificate',
        'ReverseConvertible',
        'MultiBarrierReverseConvertible',
        'MultiReverseConvertible',
        'SoftCallableBarrierReverseConvertible',
        'SoftCallableMultiBarrierReverseConvertible',
        'SoftCallableReverseConvertible',
        'SoftCallableMultiReverseConvertible',
        'TrackerCertificate',
        'TrackerCertificateOpenEnd',
        'ActivelyManagedTrackerCertificate',
        'TemporaryProduct',
        'CapitalProtectionWithParticipationCappedWorstOf',
        'CapitalProtectionWithParticipationCappedSumBasket',
        'CapitalProtectionWithParticipationCapped',
        'CapitalProtectionWithParticipationSumBasket',
        'CapitalProtectionWithConditionalMemoryCouponWorstOf',
        'CapitalProtectionWithConditionalCouponWorstOf'
    ];

    // Init applicationService.
    function TableConfigService(
        AutocallableBarrierReverseConvertible,
        AutocallableMultiBarrierReverseConvertible,
        AutocallableMultiReverseConvertible,
        AutocallableMultiReverseConvertibleWithConditionalCoupon,
        AutocallableReverseConvertible,
        AutocallableReverseConvertibleWithConditionalCoupon,
        AutocallableBarrierReverseConvertibleWithConditionalMemoryCoupon,
        AutocallableMultiBarrierReverseConvertibleWithConditionalMemoryCoupon,
        AutocallableBarrierReverseConvertibleWithConditionalCoupon,
        AutocallableMultiBarrierReverseConvertibleWithConditionalCoupon,
        AutocallableReverseConvertibleWithConditionalMemoryCoupon,
        AutocallableMultiReverseConvertibleWithConditionalMemoryCoupon,
        BarrierReverseConvertible,
        BonusCertificate,
        CapitalProtectionWithParticipation,
        CapitalProtectionWithParticipationWorstOf,
        DiscountCertificate,
        ReverseConvertible,
        MultiBarrierReverseConvertible,
        MultiReverseConvertible,
        SoftCallableBarrierReverseConvertible,
        SoftCallableMultiBarrierReverseConvertible,
        SoftCallableReverseConvertible,
        SoftCallableMultiReverseConvertible,
        TrackerCertificate,
        TrackerCertificateOpenEnd,
        ActivelyManagedTrackerCertificate,
        TemporaryProduct,
        CapitalProtectionWithParticipationCappedWorstOf,
        CapitalProtectionWithParticipationCappedSumBasket,
        CapitalProtectionWithParticipationCapped,
        CapitalProtectionWithParticipationSumBasket,
        CapitalProtectionWithConditionalMemoryCouponWorstOf,
        CapitalProtectionWithConditionalCouponWorstOf
    ) {
        return {

            instrumentGroups: {
                'AutocallableBarrierReverseConvertible': AutocallableBarrierReverseConvertible,
                'AutocallableMultiBarrierReverseConvertible': AutocallableMultiBarrierReverseConvertible,
                'AutocallableMultiReverseConvertible': AutocallableMultiReverseConvertible,
                'AutocallableMultiReverseConvertibleWithConditionalCoupon': AutocallableMultiReverseConvertibleWithConditionalCoupon,
                'AutocallableReverseConvertible': AutocallableReverseConvertible,
                'AutocallableReverseConvertibleWithConditionalCoupon': AutocallableReverseConvertibleWithConditionalCoupon,
                'AutocallableBarrierReverseConvertibleWithConditionalMemoryCoupon': AutocallableBarrierReverseConvertibleWithConditionalMemoryCoupon,
                'AutocallableMultiBarrierReverseConvertibleWithConditionalMemoryCoupon': AutocallableMultiBarrierReverseConvertibleWithConditionalMemoryCoupon,
                'AutocallableBarrierReverseConvertibleWithConditionalCoupon': AutocallableBarrierReverseConvertibleWithConditionalCoupon,
                'AutocallableMultiBarrierReverseConvertibleWithConditionalCoupon': AutocallableMultiBarrierReverseConvertibleWithConditionalCoupon,
                'AutocallableReverseConvertibleWithConditionalMemoryCoupon': AutocallableReverseConvertibleWithConditionalMemoryCoupon,
                'AutocallableMultiReverseConvertibleWithConditionalMemoryCoupon': AutocallableMultiReverseConvertibleWithConditionalMemoryCoupon,
                'BarrierReverseConvertible': BarrierReverseConvertible,
                'BonusCertificate': BonusCertificate,
                'CapitalProtectionWithParticipation': CapitalProtectionWithParticipation,
                'CapitalProtectionWithParticipationWorstOf': CapitalProtectionWithParticipationWorstOf,
                'DiscountCertificate': DiscountCertificate,
                'ReverseConvertible': ReverseConvertible,
                'MultiBarrierReverseConvertible': MultiBarrierReverseConvertible,
                'MultiReverseConvertible': MultiReverseConvertible,
                'SoftCallableBarrierReverseConvertible': SoftCallableBarrierReverseConvertible,
                'SoftCallableMultiBarrierReverseConvertible': SoftCallableMultiBarrierReverseConvertible,
                'SoftCallableReverseConvertible': SoftCallableReverseConvertible,
                'SoftCallableMultiReverseConvertible': SoftCallableMultiReverseConvertible,
                'TrackerCertificate': TrackerCertificate,
                'TrackerCertificateOpenEnd': TrackerCertificateOpenEnd,
                'ActivelyManagedTrackerCertificate': ActivelyManagedTrackerCertificate,
                'TemporaryProduct': TemporaryProduct,
                'CapitalProtectionWithParticipationCappedWorstOf': CapitalProtectionWithParticipationCappedWorstOf,
                'CapitalProtectionWithParticipationCappedSumBasket': CapitalProtectionWithParticipationCappedSumBasket,
                'CapitalProtectionWithParticipationCapped': CapitalProtectionWithParticipationCapped,
                'CapitalProtectionWithParticipationSumBasket': CapitalProtectionWithParticipationSumBasket,
                'CapitalProtectionWithConditionalMemoryCouponWorstOf': CapitalProtectionWithConditionalMemoryCouponWorstOf,
                'CapitalProtectionWithConditionalCouponWorstOf': CapitalProtectionWithConditionalCouponWorstOf
            },

            /**
             * Returns table config for specific instrument group
             * @param {string} groupId - Instrument group
             */
            get: function(groupId) {
                return this.instrumentGroups[groupId];
            }
        };
    }
})();
var lang_it_CH = {
    "1D": "1G",
    "1M": "1M",
    "1Y": "1A",
    "3M": "3M",
    "6M": "6M",
    "30Y": "MAX",
    "abort": "Interrompere",
    "accept_legal_information": "Accetto le note legali riguardanti i Prodotti Strutturati.",
    "accept": "Accettare",
    "access_restriction": "Limitazione di accesso",
    "accruedInterestPriCurPercent": "Interessi maturati",
    "active_filter": "Filtri attivi",
    "adjust": "Adjust",
    "filter_count_mobile": "Adattare il filtro",
    "advanced_filters": "Filtri avanzati",
    "all": "Tutti",
    "annually": "annuale",
    "apply": "Confermare",
    "ask_criteria": "Lettera",
    "ask": "Lettera",
    "mainmenu": "Menu principale",
    "at_expiry": "A scadenza",
    "Autocallable_SoftCallable_MultiBarrierReverseConvertible": "Autocallable / Softcallable <br/>Multi Barrier Reverse Convertible",
    "AutocallableBarrierReverseConvertible": "Autocallable Barrier Reverse Convertible",
    "AutocallableBarrierReverseConvertibles": "Autocallable Barrier Reverse Convertible",
    "AutocallableBarrierReverseConvertibleWithConditionalCoupon": "Autocallable Barrier Reverse Convertible con Cedola Condizionale",
    "AutocallableBarrierReverseConvertiblesWithConditionalCoupon": "Autocallable Barrier Reverse Convertible con Cedola Condizionale",
    "AutocallableBarrierReverseConvertibleWithConditionalMemoryCoupon": "Autocallable Barrier Reverse Convertible con Cedola Condizionale Memory",
    "AutocallableBarrierReverseConvertiblesWithConditionalMemoryCoupon": "Autocallable Barrier Reverse Convertible con Cedola Condizionale Memory",
    "AutocallableMultiBarrierReverseConvertible": "Autocallable Multi Barrier Reverse Convertible",
    "AutocallableMultiBarrierReverseConvertibles": "Autocallable Multi Barrier Reverse Convertible",
    "AutocallableMultiBarrierReverseConvertibleWithConditionalCoupon": "Autocallable Multi Barrier Reverse Convertible con Cedola Condizionale",
    "AutocallableMultiBarrierReverseConvertiblesWithConditionalCoupon": "Autocallable Multi Barrier Reverse Convertible con Cedola Condizionale",
    "AutocallableMultiBarrierReverseConvertibleWithConditionalMemoryCoupon": "Autocallable Multi Barrier Reverse Convertible con Cedola Condizionale Memory",
    "AutocallableMultiBarrierReverseConvertiblesWithConditionalMemoryCoupon": "Autocallable Multi Barrier Reverse Convertible con Cedola Condizionale Memory",
    "AutocallableMultiReverseConvertible": "Autocallable Multi Reverse Convertible",
    "AutocallableMultiReverseConvertibles": "Autocallable Multi Reverse Convertible",
    "AutocallableMultiReverseConvertibleWithConditionalCoupon": "Autocallable Multi Barrier Reverse Convertible con Cedola Condizionale",
    "AutocallableMultiReverseConvertiblesWithConditionalCoupon": "Autocallable Multi Barrier Reverse Convertible con Cedola Condizionale",
    "AutocallableMultiReverseConvertibleWithConditionalMemoryCoupon": "Autocallable Multi Reverse Convertible con Cedola Condizionale Memory",
    "AutocallableMultiReverseConvertiblesWithConditionalMemoryCoupon": "Autocallable Multi Reverse Convertible con Cedola Condizionale Memory",
    "AutocallableReverseConvertible": "Autocallable Reverse Convertible",
    "AutocallableReverseConvertibles": "Autocallable Reverse Convertible",
    "AutocallableReverseConvertibleWithConditionalCoupon": "Autocallable Reverse Convertible con Cedola Condizionale",
    "AutocallableReverseConvertibleWithConditionalCoupons": "AutocallableReverseConvertibleWithConditionalCoupons",
    "AutocallableReverseConvertiblesWithConditionalCoupon": "Autocallable Reverse Convertible con Cedola Condizionale",
    "AutocallableReverseConvertibleWithConditionalMemoryCoupon": "Autocallable Reverse Convertible con Cedola Condizionale Memory",
    "AutocallableReverseConvertiblesWithConditionalMemoryCoupon": "Autocallable Reverse Convertible con Cedola Condizionale Memory",
    "back": "Indietro",
    "barrier_too_close_multi_notification": "Il margine di sicurezza è quasi esaurito (distanza dalla barriera del sottostante più debole < 5.00%).",
    "show_alternative_products": "Mostrare prodotti alternativi",
    "barrier_too_close_single_notification": "Il margine di sicurezza è quasi esaurito (distanza dalla barriera < 5.00%).",
    "barrier": "Barriera",
    "barrierHitProbability2MaturityPercent": "Probabilità di toccare la barriera",
    "barrierLevelAbsoluteReferenceCurrency": "Barriera",
    "barrierLevelRelativePercent": "Livello di barriera",
    "barrierObservationMethod": "Tipo di barriera",
    "BarrierReverseConvertible": "Barrier Reverse Convertible",
    "BarrierReverseConvertibles": "Barrier Reverse Convertible",
    "barrierTouched_criteria": "Barriera raggiunta",
    "barrierTouched": "Barriera raggiunta",
    "betaVersion": "Versione Beta",
    "betaVersionText": "Versione Beta con funzionalità limitate",
    "bi-monthly": "Ogni 2 mesi",
    "bi-weekly": "Bisettimanale",
    "bid": "Denaro",
    "BonusCertificate": "Certificato Bonus",
    "TrackerCertificates": "Certificato Tracker",
    "TrackerCertificate": "Certificato Tracker (statico)",
    "TrackerCertificateName": "LUKB Certificato Tracker",
    "TrackerCertificateCategory": "Categoria SSPA: 1300 - A partecipazione",
    "TrackerCertificateOpenEnd": "Certificato Tracker Open End (statico)",
    "TrackerCertificateOpenEndName": "LUKB Certificato Tracker",
    "TrackerCertificateOpenEndCategory": "Categoria SSPA: 1300 - A partecipazione",
    "TemporaryProduct": "Temporary Product",
    "browser_title_product_detail": "Pagina del prodotto",
    "call": "Chiamateci",
    "cancel": "Annullare",
    "CapitalProtectionCertificatesWithParticipation": "Certificato a Protezione del Capitale con Partecipazione",
    "CapitalProtectionCertificateWithParticipation": "Certificato a Protezione del Capitale con Partecipazione",
    "CapitalProtectionCertificateWithParticipationWorstOf": "Certificato a Protezione del Capitale con Partecipazione al worst of",
    "CapitalProtectionCertificatesWithParticipationWorstOf": "Certificato a Protezione del Capitale con Partecipazione al worst of",
    "CapitalProtectionProduct": "Certificato a Protezione del Capitale",
    "CapitalProtectionProducts": "Certificato a Protezione del Capitale",
    "CapitalProtections": "Protezione del capitale",
    "CapitalProtection": "Protezione del capitale",
    "CapitalProtectionWithParticipation": "Certificato a Protezione del Capitale con Partecipazione",
    "CapitalProtectionWithParticipationWorstOf": "Certificato a Protezione del Capitale con Partecipazione al worst of",
    "CapitalProtectionWithParticipationCapped": "Certificato a Protezione del Capitale con Partecipazione limitata",
    "CapitalProtectionWithParticipationSumBasket": "Certificato a Protezione del Capitale con Partecipazione al Basket",
    "CapitalProtectionWithParticipationSumBasket_SPPNameGeneration": "Certificato a Protezione del Capitale con Partecipazione",
    "CapitalProtectionWithParticipationCappedSumBasket": "Certificato a Protezione del Capitale con Partecipazione limitata al Basket",
    "CapitalProtectionWithParticipationCappedSumBasket_SPPNameGeneration": "Certificato a Protezione del Capitale con Partecipazione limitata",
    "CapitalProtectionWithConditionalCouponWorstOf": "Certificato a Protezione del Capitale con Cedola Condizionale",
    "CapitalProtectionWithConditionalCouponWorstOf_SPPNameGeneration": "Certificato a Protezione del Capitale con Cedola Condizionale %s",
    "CapitalProtectionWithConditionalMemoryCouponWorstOf_SPPNameGeneration": "Certificato a Protezione del Capitale con Cedola Condizionale Memory %s",
    "cash_settlement": "Rimborso in contanti",
    "cash": "Rimborso in contanti",
    "cashorphysical": "In contanti o consegna fisica",
    "categorization": "Categorizzazione",
    "change_criteria": "Cambiare i criteri",
    "session_close": "Chiusura giornaliera",
    "chart_disclaimer_BarrierReverseConvertible": "Performance del prodotto, rendimento max. a scadenza, rendimento max. p.a. e probabilità di toccare la barriera si basano sui corsi attuali.<br/>Performance del sottostante e corso del sottostante si basano sul corso di chiusura del giorno precedente.<br/>La «Performance del prodotto dall'emissione» considera sia l'evoluzione del prezzo del prodotto che le cedole già corrisposte dall'emissione.",
    "chart_disclaimer_DiscountCertificate": "Performance del prodotto, rendimento max. a scadenza e rendimento max. p.a. si basano sui corsi attuali.<br/>Performance del sottostante e corso del sottostante si basano sul corso di chiusura del giorno precedente.",
    "chart_disclaimer_AutocallableBarrierReverseConvertibleWithConditionalMemoryCoupon": "Performance del prodotto, rendimento max. a scadenza, rendimento max. p.a. e probabilità di toccare la barriera si basano sui corsi attuali.<br/>Performance del sottostante e corso del sottostante si basano sul corso di chiusura del giorno precedente.<br/>La «Performance del prodotto dall'emissione» considera sia l'evoluzione del prezzo del prodotto che le cedole già corrisposte dall'emissione.",
    "chart_disclaimer_AutocallableBarrierReverseConvertibleWithConditionalCoupon": "Performance del prodotto, rendimento max. a scadenza, rendimento max. p.a. e probabilità di toccare la barriera si basano sui corsi attuali.<br/>Performance del sottostante e corso del sottostante si basano sul corso di chiusura del giorno precedente.<br/>La «Performance del prodotto dall'emissione» considera sia l'evoluzione del prezzo del prodotto che le cedole già corrisposte dall'emissione.",
    "chart_disclaimer_MultiBarrierReverseConvertible": "Performance del prodotto, rendimento max. a scadenza, rendimento max. p.a. e probabilità di toccare la barriera si basano sui corsi attuali.<br/>Performance del sottostante più debole, sottostante più debole e corsi dei sottostanti si basano sui corsi di chiusura del giorno precedente.<br/>La «Performance del prodotto dall'emissione» considera sia l'evoluzione del prezzo del prodotto che le cedole già corrisposte dall'emissione.",
    "chart_disclaimer_AutocallableMultiBarrierReverseConvertibleWithConditionalMemoryCoupon": "Performance del prodotto, rendimento max. a scadenza, rendimento max. p.a. e probabilità di toccare la barriera si basano sui corsi attuali.<br/>Performance del sottostante più debole, sottostante più debole e corsi dei sottostanti si basano sui corsi di chiusura del giorno precedente.<br/>La «Performance del prodotto dall'emissione» considera sia l'evoluzione del prezzo del prodotto che le cedole già corrisposte dall'emissione.",
    "chart_disclaimer_AutocallableMultiBarrierReverseConvertibleWithConditionalCoupon": "Performance del prodotto, rendimento max. a scadenza, rendimento max. p.a. e probabilità di toccare la barriera si basano sui corsi attuali.<br/>Performance del sottostante più debole, sottostante più debole e corsi dei sottostanti si basano sui corsi di chiusura del giorno precedente.<br/>La «Performance del prodotto dall'emissione» considera sia l'evoluzione del prezzo del prodotto che le cedole già corrisposte dall'emissione.",
    "chart_disclaimer_AutocallableBarrierReverseConvertible": "Performance del prodotto, rendimento max. a scadenza, rendimento max. p.a., yield to call e probabilità di toccare la barriera si basano sui corsi attuali.<br/>Performance del sottostante e corso del sottostante si basano sul corso di chiusura del giorno precedente.<br/>La «Performance del prodotto dall'emissione» considera sia l'evoluzione del prezzo del prodotto che le cedole già corrisposte dall'emissione.",
    "chart_disclaimer_SoftCallableBarrierReverseConvertible": "Performance del prodotto, rendimento max. a scadenza, rendimento max. p.a., yield to call e probabilità di toccare la barriera si basano sui corsi attuali.<br/>Performance del sottostante e corso del sottostante si basano sul corso di chiusura del giorno precedente.<br/>La «Performance del prodotto dall'emissione» considera sia l'evoluzione del prezzo del prodotto che le cedole già corrisposte dall'emissione.",
    "chart_disclaimer_AutocallableMultiBarrierReverseConvertible": "Performance del prodotto, rendimento max. a scadenza, rendimento max. p.a., yield to call e probabilità di toccare la barriera si basano sui corsi attuali.<br/>Performance del sottostante più debole, sottostante più debole e corsi dei sottostanti si basano sui corsi di chiusura del giorno precedente.<br/>La «Performance del prodotto dall'emissione» considera sia l'evoluzione del prezzo del prodotto che le cedole già corrisposte dall'emissione.",
    "chart_disclaimer_SoftCallableMultiBarrierReverseConvertible": "Performance del prodotto, rendimento max. a scadenza, rendimento max. p.a., yield to call e probabilità di toccare la barriera si basano sui corsi attuali.<br/>Performance del sottostante più debole, sottostante più debole e corsi dei sottostanti si basano sui corsi di chiusura del giorno precedente.<br/>La «Performance del prodotto dall'emissione» considera sia l'evoluzione del prezzo del prodotto che le cedole già corrisposte dall'emissione.",
    "chart_disclaimer_ReverseConvertible": "Performance del prodotto, rendimento max. a scadenza e rendimento max. p.a. si basano sui corsi attuali.<br/>Performance del sottostante e corso del sottostante si basano sul corso di chiusura del giorno precedente.<br/>La «Performance del prodotto dall'emissione» considera sia l'evoluzione del prezzo del prodotto che le cedole già corrisposte dall'emissione.",
    "chart_disclaimer_AutocallableReverseConvertibleWithConditionalMemoryCoupon": "Performance del prodotto, rendimento max. a scadenza e rendimento max. p.a. si basano sui corsi attuali.<br/>Performance del sottostante e corso del sottostante si basano sul corso di chiusura del giorno precedente.<br/>La «Performance del prodotto dall'emissione» considera sia l'evoluzione del prezzo del prodotto che le cedole già corrisposte dall'emissione.",
    "chart_disclaimer_AutocallableReverseConvertiblesWithConditionalCoupon": "Performance del prodotto, rendimento max. a scadenza e rendimento max. p.a. si basano sui corsi attuali.<br/>Performance del sottostante e corso del sottostante si basano sul corso di chiusura del giorno precedente.<br/>La «Performance del prodotto dall'emissione» considera sia l'evoluzione del prezzo del prodotto che le cedole già corrisposte dall'emissione.",
    "chart_disclaimer_MultiReverseConvertible": "Performance del prodotto, rendimento max. a scadenza e rendimento max. p.a. si basano sui corsi attuali.<br/>Performance del sottostante più debole, sottostante più debole e corsi dei sottostanti si basano sui corsi di chiusura del giorno precedente.<br/>La «Performance del prodotto dall'emissione» considera sia l'evoluzione del prezzo del prodotto che le cedole già corrisposte dall'emissione.",
    "chart_disclaimer_AutocallableMultiReverseConvertibleWithConditionalMemoryCoupon": "Performance del prodotto, rendimento max. a scadenza e rendimento max. p.a. si basano sui corsi attuali.<br/>Performance del sottostante più debole, sottostante più debole e corsi dei sottostanti si basano sui corsi di chiusura del giorno precedente.<br/>La «Performance del prodotto dall'emissione» considera sia l'evoluzione del prezzo del prodotto che le cedole già corrisposte dall'emissione.",
    "chart_disclaimer_AutocallableMultiReverseConvertibleWithConditionalCoupon": "Performance del prodotto, rendimento max. a scadenza e rendimento max. p.a. si basano sui corsi attuali.<br/>Performance del sottostante più debole, sottostante più debole e corsi dei sottostanti si basano sui corsi di chiusura del giorno precedente.<br/>La «Performance del prodotto dall'emissione» considera sia l'evoluzione del prezzo del prodotto che le cedole già corrisposte dall'emissione.",
    "chart_disclaimer_AutocallableReverseConvertible": "Performance del prodotto, rendimento max. a scadenza, rendimento max. p.a. e yield to call si basano sui corsi attuali.<br/>Performance del sottostante e corso del sottostante si basano sul corso di chiusura del giorno precedente.<br/>La «Performance del prodotto dall'emissione» considera sia l'evoluzione del prezzo del prodotto che le cedole già corrisposte dall'emissione.",
    "chart_disclaimer_SoftCallableReverseConvertible": "Performance del prodotto, rendimento max. a scadenza, rendimento max. p.a. e yield to call si basano sui corsi attuali.<br/>Performance del sottostante e corso del sottostante si basano sul corso di chiusura del giorno precedente.<br/>La «Performance del prodotto dall'emissione» considera sia l'evoluzione del prezzo del prodotto che le cedole già corrisposte dall'emissione.",
    "chart_disclaimer_AutocallableMultiReverseConvertible": "Performance del prodotto, rendimento max. a scadenza, rendimento max. p.a. e yield to call si basano sui corsi attuali.<br/>Performance del sottostante più debole, sottostante più debole e corsi dei sottostanti si basano sui corsi di chiusura del giorno precedente.<br/>La «Performance del prodotto dall'emissione» considera sia l'evoluzione del prezzo del prodotto che le cedole già corrisposte dall'emissione.",
    "chart_disclaimer_SoftCallableMultiReverseConvertible": "Performance del prodotto, rendimento max. a scadenza, rendimento max. p.a. e yield to call si basano sui corsi attuali.<br/>Performance del sottostante più debole, sottostante più debole e corsi dei sottostanti si basano sui corsi di chiusura del giorno precedente.<br/>La «Performance del prodotto dall'emissione» considera sia l'evoluzione del prezzo del prodotto che le cedole già corrisposte dall'emissione.",
    "chart_disclaimer_CapitalProtectionWithParticipation": "Performance del prodotto si basa sui corsi attuali.<br/>Performance del sottostante, distanza strike e corso del sottostante si basano sul corso di chiusura del giorno precedente.",
    "chart_disclaimer_CapitalProtectionWithParticipationCapped": "Performance del prodotto si basa sui corsi attuali.<br/>Performance del sottostante, distanza strike e corso del sottostante si basano sul corso di chiusura del giorno precedente.",
    "chart_disclaimer_CapitalProtectionWithParticipationCappedSumBasket": "Performance del prodotto si basa sui corsi attuali.<br/>Performance del sottostante, distanza strike e corso del sottostante si basano sul corso di chiusura del giorno precedente.",
    "chart_disclaimer_CapitalProtectionWithParticipationWorstOf": "Performance del prodotto si basa sui corsi attuali.<br/>Performance del sottostante più debole, sottostante più debole, distanza strike e corsi dei sottostanti si basano sul corso di chiusura del giorno precedente.",
    "chart_disclaimer_CapitalProtectionWithParticipationCappedWorstOf": "Performance del prodotto si basa sui corsi attuali.<br/>Performance del sottostante più debole, sottostante più debole, distanza strike e corsi dei sottostanti si basano sul corso di chiusura del giorno precedente.",
    "chart_disclaimer_TrackerCertificate": "La «Performance del prodotto dall'emissione» considera sia l'evoluzione del prezzo del prodotto che le cedole e i pagamenti di compensazione già corrisposte dall'emissione.",
    "chart_disclaimer_TrackerCertificateOpenEnd": "La «Performance del prodotto dall'emissione» considera sia l'evoluzione del prezzo del prodotto che le cedole e i pagamenti di compensazione già corrisposte dall'emissione.",
    "chart_disclaimer_ActivelyManagedTrackerCertificate": "La «Performance del prodotto dall'emissione» considera sia l'evoluzione del prezzo del prodotto che le cedole e i pagamenti di compensazione già corrisposte dall'emissione.",
    "chart_disclaimer_CapitalProtectionWithParticipationSumBasket": "Performance del prodotto si basa sui corsi attuali.<br/>Performance del sottostante, distanza strike e corso del sottostante si basano sul corso di chiusura del giorno precedente.",
    "chart_disclaimer_CapitalProtectionWithConditionalCouponWorstOf": "Performance del prodotto si basa sui corsi attuali.<br/>Performance del sottostante più debole, sottostante più debole, distanza strike e corsi dei sottostanti si basano sul corso di chiusura del giorno precedente.",
    "chart_disclaimer_CapitalProtectionWithConditionalMemoryCouponWorstOf": "Performance del prodotto si basa sui corsi attuali.<br/>Performance del sottostante più debole, sottostante più debole, distanza strike e corsi dei sottostanti si basano sul corso di chiusura del giorno precedente.",
    "conditionalCouponPercentPa": "Cedola p.a.",
    "confirm_residences": "Confermo che il mio domicilio/la mia sede è in Svizzera.",
    "contact_person": "Persone di contatto",
    "contact": "Contatto",
    "continuous": "continua",
    "couponInterest": "Cedola",
    "couponPaymentDate": "Data di pagamento",
    "couponPaymentDateAndInterest": "Date di pagamento delle cedole",
    "couponPaymentFrequency": "Frequenza di pagamento della cedola",
    "couponPercentPa": "Cedola p.a.",
    "couponPercentPa_criteria": "Cedola p.a. %",
    "coupons": "Cedola",
    "create_pdf_from_site": "Creare un PDF della pagina",
    "criteria": "Criteri",
    "currency": "Valuta",
    "daily_close_prices": "Chiusura giornaliera",
    "daily": "giornalmente",
    "days2MaturityDate": "Durata residua al fixing finale in giorni",
    "disclaimer_footer": "Se il suo domicilio o la sua sede si trovano in un'altra nazione non è autorizzato ad accedere alle informazioni sui Prodotti Strutturati emessi dalla LUKB. In tal caso la preghiamo di interrompere la procedura.",
    "disclaimer_text": "Per accedere alle informazioni sui prodotti deve soddisfare le seguenti condizioni:",
    "DiscountCertificate": "Certificato Discount",
    "display_all_underlyings": "Mostrare tutti i sottostanti",
    "display_all": "Mostra tutti",
    "display_all_products": "Mostrare tutti i prodotti",
    "distance2BarrierLevelPercent": "Distanza Barriera",
    "distance2BarrierLevelPercent_criteria": "Distanza Barriera %",
    "distance2ClosestBarrierLevelPercent": "Distanza Barriera",
    "distance2StrikePercent": "Distanza Strike",
    "distance2StrikePercent_criteria": "Distanza Strike %",
    "distance2triggerLevelRefCurrencyPercent": "Distanza Autocall",
    "distance2triggerLevelRefCurrencyPercent_criteria": "Distanza Autocall %",
    "distanceRange2BarrierLevel": "Distanza Barriera",
    "download": "Download",
    "downloads": "Downloads",
    "dr": "Dr.",
    "early_redemption": "Rimborso anticipato",
    "earlyRedemptionList": "Rimborso anticipato",
    "email_address": "Indirizzo E-Mail",
    "error_email": "Per favore inserisca un indirizzo E-Mail valido.",
    "error_full_name": "Per favore inserisca un nome e cognome.",
    "error_message": "La preghiamo di inserire un messaggio.",
    "error_phone": "Per favore inserisca un numero di telefono.",
    "error_salutation": "Per favore selezioni un appellativo.",
    "every": "ogni",
    "factsheet_dl": "Scaricare il Factsheet",
    "factsheet": "Factsheet",
    "false": "No",
    "filter": "Filter",
    "filter_barrierObservationMethod": "Tipo di barriera",
    "filter_currency": "Valuta",
    "filter_distanceRange2BarrierLevel": "Margine di sicurezza",
    "filter_group": "Gruppo di prodotto / Tipo di prodotto",
    "filter_maturityDate": "Data di scadenza",
    "filter_products": "Filtrare i prodotti",
    "filter_underlying": "Sottostante",
    "first_last_name": "Nome e Cognome",
    "found_products": "Prodotti trovati",
    "glossary_disclaimer": "*Per i prodotti con più sottostanti si tratta del sottostante con la performance più debole",
    "glossary": "Glossario",
    "group_names": "Tipo di prodotto",
    "groupName": "Tipo di prodotto",
    "hasTouchedBarrier": "Barriera raggiunta",
    "header_structured_products": "Prodotti Strutturati",
    "hide_all_underlyings": "Nascondere tutti i sottostanti",
    "id_ex_swx_exists_criteria": "EX SWX ID esiste",
    "id_ex_swx_exists": "EX SWX ID esiste",
    "in_subscription_notification": "Questo prodotto è in sottoscrizione fino al giorno {{subscriptionPeriodEndDate}}, ore {{subscriptionPeriodEndDateTime}}.",
    "inListing_criteria": "Prodotto quotato",
    "inListing": "Prodotto quotato",
    "isin": "ISIN",
    "isQuanto": "Quanto",
    "issued_products": "Prodotti emessi",
    "issuePrice": "Prezzo d'emissione",
    "issuer": "Emittente",
    "issuerCallNoticePeriodEndDate": "Data di osservazione",
    "key_figures": "Indicatori chiave",
    "lang_DE": "Tedesco",
    "lang_EN": "Inglese",
    "lang_FR": "Francese",
    "lang_IT": "Italiano",
    "lastTradingDate": "Ultimo giorno di negoziazione",
    "less_then_one_month": "< 1 mese",
    "lifecycle": "Ciclo di vita",
    "light_footer_title": "Siamo qui per voi",
    "listing": "Quotazione",
    "market_data_source": "I dati di mercato sono messi a disposizione da Morningstar e <a href='http://www.solvians.com' title='Solvians' target='_blank'>Solvians</a>.",
    "maturityDate": "Scadenza",
    "max_performance_too_low_notification": "Questo prodotto si avvicina al rendimento massimo (<1.50%).",
    "max": "Max.",
    "MAX": "Max.",
    "maxPerformancePercentPaPriceCurrency": "Rendimento max. p.a.",
    "maxPerformancePercentPaPriceCurrency_criteria": "Rendimento max. p.a. %",
    "maxPerformancePercentPaStrikeCurrency": "Cedola Condizionale Memory p.a.",
    "maxPerformancePercentStrikeCurrency_criteria": "Rendimento max. a scadenza %",
    "maxPerformancePercentStrikeCurrency": "Rendimento max. a scadenza",
    "maxPerformancePercentPriceCurrency": "Rendimento max. a scadenza",
    "distance2CapPercentStrikeCurrency_criteria": "Distanza Barriera %",
    "distance2CapPercentStrikeCurrency": "Distanza Barriera",
    "distance2CapAbsoluteStrikeCurrency_level": "Distanza livello cap",
    "relativeCapStrCur2ReferencePrice_criteria": "Livello cap in %",
    "relativeCapStrCur2ReferencePrice": "Livello cap",
    "reference_price_absolute": "Initial Fixing",
    "discount": "Discount",
    "strikeLevelAndDiscount": "Cap e Discount",
    "metaGroup": "Gruppo di prodotto",
    "metaGroupName": "Gruppo di prodotto",
    "min": "Min.",
    "month": "mese",
    "monthly": "mensile",
    "months_dat": "mesi",
    "months": "mesi",
    "more_then_2_years": "> 2 anni",
    "mr": "Signor",
    "ms": "Signora",
    "MultiBarrierReverseConvertible": "Multi Barrier Reverse Convertible",
    "MultiBarrierReverseConvertibles": "Multi Barrier Reverse Convertible",
    "MultiReverseConvertible": "Multi Reverse Convertible",
    "MultiReverseConvertibles": "Multi Reverse Convertible",
    "Max_Yeild_At_Expiry": "Rendimento max. a scadenza",
    "name_term": "Nome, definizione...",
    "no_document_available": "Documento non disponibile",
    "no_results": "Nessun risultato",
    "no_search_results": "Nessun risultato",
    "no": "No",
    "notification_link": "Di seguito trova una selezione di prodotti",
    "notionalValue": "Nominale",
    "observationDate": "Data di osservazione",
    "observationDateTrim": "Data di osservazione",
    "on": "su",
    "one_to_three_months": "1 - 3 mesi",
    "one_to_two_years": "1 - 2 anni",
    "onlyQuotedProducts": "Mostrare solo prodotti quotati",
    "open_end": "Open-end",
    "participationRatePercent": "Partecipazione",
    "Participations": "Partecipazione",
    "pdf_create": "Report del prodotto",
    "performance_since_issue_percent": "Performance dall'emissione",
    "performance": "Performance",
    "performance2NextObservationDatePriceCurrency_criteria": "Yield to Call %",
    "performance2NextObservationDatePriceCurrency": "Yield to Call",
    "performanceSinceIssuePercent": "Performance del prodotto dall'emissione",
    "distance2StrikePercentForWorstPerformanceUnderlying": "Distanza del sottostante con la performance più debole dallo Strike",
    "phone_by_day": "Numero di telefono (durante il giorno)",
    "phone": "Telefono",
    "physical_settlement": "Consegna fisica dell'ETF",
    "physical": "Consegna fisica",
    "price": "Corso",
    "product_barrier_observed_on_expiry": "Osservazione della barriera a scadenza",
    "product_cash_settlement": "Rimborso in contanti",
    "product_category": "Categoria di prodotto",
    "capitalProtectionAndStrike": "Livello di protezione del capitale e Strike Level",
    "participationAndCap": "Partecipazione e Cap",
    "barrierAndStrike": "Barriera e Strike Level",
    "product_detail_fixingDate": "Data di fixing iniziale",
    "product_detail_issueDate": "Data d'emissione",
    "product_detail_maturityDate": "Data di fixing finale",
    "product_detail_redemptionDate": "Data di rimborso",
    "product_detail_subscriptionPeriodEndDate": "Data di chiusura della sottoscrizione",
    "product_early_redemption_option": "Possibilità di rimborso anticipato",
    "product_group_product_type": "Gruppo di prodotto / Tipo di prodotto",
    "product_overview_performance_key_indicators": "Panoramica del prodotto, performance e indicatori chiave",
    "product_overview": "Panoramica del prodotto",
    "product_physical_delivery": "Consegna fisica dell'ETF",
    "product_quanto": "Il prodotto è protetto contro il rischio di cambio",
    "product_type_criteria": "Gruppo di prodotto / Tipo di prodotto",
    "product_type_search_placeholder": "Gruppo di prodotto / Tipo di prodotto…",
    "product_type": "Tipo di prodotto",
    "product_underlying_search_placeholder": "Nome, definizione...",
    "product": "Prodotto",
    "products_documents": "Mostrare i dettagli del prodotto",
    "products_in_subscription_of_lukb": "Prodotti in sottoscrizione della Luzerner Kantonalbank",
    "products_in_subscription": "Prodotti in<br/>sottoscrizione",
    "products_range_0": "3 mesi",
    "products_range_1": "6 mesi",
    "products_range_2": "9 mesi",
    "products_range_3": "1 anno",
    "products_range_4": "18 mesi",
    "products_range_5": "> 18 mesi",
    "products": "Prodotti",
    "protectionLevelRelativePercent": "Livello di protezione del capitale",
    "quanto": "Quanto",
    "quarterly": "trimestrale",
    "quotingMethod": "Tipo di quotazione",
    "clean": "Clean",
    "dirty": "Dirty",
    "realPriceCurrency": "Valuta",
    "redemptionDate": "Scadenza",
    "relativeStrike2ReferencePrice": "Strike Level",
    "repayment": "Possibilità di rimborso anticipato",
    "repaymentLevelPriCurPercent": "Prossima data di osservazione rimborso anticipato",
    "repaymentLevelPriCurPercentSoftCallable": "Prossima data di osservazione",
    "repaymentValue": "Rimborso",
    "reset_all_filter": "Cancellare i filtri",
    "ReverseConvertible": "Reverse Convertible",
    "ReverseConvertibles": "Reverse Convertible",
    "salutation": "Appellativo",
    "search_groups_on": " su",
    "search_placeholder": "Numero di valore / ISIN / Simbolo / Sottostante / Tipo di prodotto",
    "search": "Ricerca prodotto",
    "select_language": "Selezioni una lingua",
    "semi-annually": "semestrale",
    "send_copy_to_email": "Inviare una copia al mio indirizzo E-Mail",
    "send_message": "Inviare il messaggio",
    "settlementMethod": "Modalità di rimborso",
    "show_documents (not used anymore)": "Mostrare i documenti",
    "show_product_detail_page": "Mostrare i dettagli del prodotto",
    "show_product": "Mostrare il prodotto",
    "show_products": "Mostrare i prodotti",
    "shown_for_7_days": "Non mostrare più il Disclaimer per i prossimi 7 giorni.",
    "six_symbol": "Simbolo",
    "six_to_twelve_months": "6 - 12 mesi",
    "SoftCallableBarrierReverseConvertible": "Softcallable Barrier Reverse Convertible",
    "SoftCallableBarrierReverseConvertibles": "Softcallable Barrier Reverse Convertible",
    "SoftCallableMultiBarrierReverseConvertible": "Softcallable Multi Barrier Reverse Convertible",
    "SoftCallableMultiBarrierReverseConvertibles": "Softcallable Multi Barrier Reverse Convertible",
    "SoftCallableMultiReverseConvertible": "Softcallable Multi Reverse Convertible",
    "SoftCallableMultiReverseConvertibles": "Softcallable Multi Reverse Convertible",
    "SoftCallableReverseConvertible": "Softcallable Reverse Convertible",
    "SoftCallableReverseConvertibles": "Softcallable Reverse Convertible",
    "starting_after": "la prima volta dopo",
    "static_data": "Dati principali",
    "strike": "Strike",
    "structured_products_lukb": "Prodotti Strutturati della Luzerner Kantonalbank",
    "subscriptionPeriodEndDate": "Chiusura della sottoscrizione",
    "subscriptionPeriodEndDateMobile": "Chiusura della<br/>sottoscrizione",
    "tab_find_products": "Trovare i<br/>prodotti",
    "tab_opportunities_in_the_secondary_market": "Opportunità sul<br/>mercato secondario",
    "tab_opportunities_secondary_market_header": "Opportunità nel mercato secondario: In questa pagina trova una selezione di interessanti Barrier Reverse Convertible - anche con possibilità di rimborso anticipato. La selezione è basata su criteri predefiniti come prezzo, barriera, yield to call. Tutti i prodotti possono essere negoziati direttamente in Borsa.",
    "tab_products_in_subscription": "Prodotti<br/>in sottoscrizione",
    "tab_recently_issued": "Emessi<br/>recentemente",
    "tab_yield_matrix_title": "La matrice dei rendimenti rappresenta uno strumento di lavoro unico e dal valore aggiunto. Questo tool le permette di trovare i più attrattivi Barrier Reverse Convertibles, Multi Barrier Reverse Convertibles così come gli Autocallable/Softcallable Barrier Reverse Convertibles nel mercato secondario. Combini i sottostanti, il cuscinetto di rischio e la durata per trovare la soluzione d'investimento ideale alle sue necessità. Cliccando sui rendimenti nella matrice, accede direttamente alla pagina del prodotto.",
    "tab_yield_matrix": "Matrice dei<br/>rendimenti",
    "termsheet_dl": "Scaricare il Termsheet",
    "final_terms_dl": "Scaricare il Condizioni definitive",
    "termsheet": "Termsheet",
    "final_terms": "Condizioni definitive",
    "third_level_filter_legend": "I presenti valori si riferiscono al valore minimo e massimo di tutti i prodotti derivanti dai filtri selezionati.",
    "three_to_six_months": "3 - 6 mesi",
    "top_of_page": "Inizio pagina",
    "triggerLevel": "Autocall Trigger Level",
    "triggerLevelRefCurAbsolute": "Autocall Trigger",
    "true": "Sì",
    "underlying": "Sottostante",
    "underlyingNames": "Sottostanti",
    "valor": "Valore",
    "weekly": "settimanale",
    "worstPerformanceUnderlying": "Sottostante più debole",
    "underlyingPerformance2ReferencePriceRelative": "Evoluzione del corso del sottostante dall'emissione",
    "worstUnderlyingPerformance2ReferencePriceRelative": "Andamento sottostante più debole dall'emissione",
    "write_message_desktop": "Scriveteci",
    "write_message_mobile": "Scriveteci",
    "write_message_tooltip": "Scriveteci",
    "write_us": "Ci scriva",
    "year": "anno",
    "years_dat": "anni",
    "years": "anni",
    "yes": "Sì",
    "YieldEnhancements": "Ottimizzazione del rendimento",
    "YieldEnhancement": "Ottimizzazione del rendimento",
    "your_message": "Il suo messaggio",
    "YTD": "YTD",
    "barrier_observation_on_maturity": "Osservazione alla scadenza",
    "contact_success_message": "<b>Grazie per il suo messaggio</b><br />Elaboreremo la sua richiesta il più presto possibile.<br/>La sua Luzerner Kantonalbank",
    "contact_error_message": "C'è stato un errore. Per favore riprovi più tardi.",
    "lang_prospectus": "Prospekt",
    "prospectus": "Prospekt",
    "chart_error": "Dati non disponibili",
    "corporate_action_executed": "Si è verificata una Corporate Action. Per ulteriori dettagli la preghiamo di consultare le <a href='https://prodottistrutturati.lukb.ch/servizi/notifiche'>notifiche</a>.",
    "maturity_notification": "Questo prodotto è arrivato a scadenza.",
    "check_products_in_subscription": "Mostrare i prodotti in sottoscrizione",
    "early_redeemed_notification": "Questo prodotto è stato richiamato per rimborsato anticipato.",
    "customDateTime": "dd.MM.y, 'ore' HH:mm",
    "prices_indicative": "I corsi denaro e lettera sono indicativi.",
    "footnote_text": "A causa del metodo di calcolo applicato, possono verificarsi dei salti in diversi indicatori chiave nel periodo tra la data di stacco della cedola e la data di pagamento della cedola.",
    "product_filter_info_no_match": "Con i criteri selezionati non è stato possibile trovare alcun prodotto.",
    "subscription_no_products": "Al momento non ci sono prodotti in sottoscrizione. La preghiamo di guardare tra i nostri prodotti sul mercato secondario.",
    "issued_no_products": "Al momento non ci sono prodotti emessi recentemente. La preghiamo di guardare tra i nostri prodotti sul mercato secondario",
    "barrier_touched_notification": "Il livello di barriera è stato raggiunto da {{touchedBarrierUnderlyingName}} in data {{firstTouchedBarrierDate}}.",
    "product_details": "Product Details",
    "phone_disclaimer": "*Le conversazioni su questa linea vengono registrate.",
    "phone_disclaimer_contact": "*Le conversazioni su queste linee vengono registrate.",
    "cookiesBannerDisclaimer": "Cliccando sul bottone OK si dichiara d'accordo riguardo l'utilizzo di Cookies per statistiche e marketing sulla nostra pagina internet. Ulteriori informazioni a riguardo si trovano <a href='/cookies'>qui</a>.",
    "contact_form": "Formulario di contatto: prodottistrutturati.lukb.ch",
    "capLevel": "Cap",
    "product_report": "Report del prodotto",
    "hours": "ore",
    "tel": "Tel",
    "Hit": "Hit",
    "customDateTimePhpFormat": "d.m.Y, \\o\\r\\e H:i",
    "BarrierReverseConvertibleName": "LUKB Barrier Reverse Convertible",
    "BarrierReverseConvertibleCategory": "Categoria SSPA: 1230 - Ottimizzazione del rendimento",
    "BarrierReverseConvertibleDescription": "LUKB Barrier Reverse Convertibles offrono agli Investitori l’opportunità di approfittare dei movimenti laterali o superiori al livello di barriera del sottostante. Questi prodotti garantiscono il pagamento di una cedola e offrono un margine di sucurezza fino al livello di barriera. Questi prodotti non prevedono alcun rimborso anticipato.",
    "MultiBarrierReverseConvertibleName": "LUKB Multi Barrier Reverse Convertible",
    "MultiBarrierReverseConvertibleCategory": "Categoria SSPA: 1230 - Ottimizzazione del rendimento",
    "MultiBarrierReverseConvertibleDescription": "LUKB Multi Barrier Reverse Convertibles offrono agli Investitori l’opportunità di approfittare dei movimenti laterali o superiori al livello di barriera dei sottostanti. Questi prodotti garantiscono il pagamento di una cedola e offrono un margine di sicurezza fino al livello di barriera, applicato al sottostante con la performance più debole. Questi prodotti non prevedono alcun rimborso anticipato.",
    "AutocallableBarrierReverseConvertibleName": "LUKB Autocallable Barrier Reverse Convertible",
    "AutocallableBarrierReverseConvertibleCategory": "Categoria SSPA: 1230 - Ottimizzazione del rendimento",
    "AutocallableBarrierReverseConvertibleDescription": "LUKB Autocallable Barrier Reverse Convertibles offrono agli Investitori l’opportunità di approfittare dei movimenti laterali o superiori al livello di barriera del sottostante. Questi prodotti garantiscono il pagamento di una cedola e offrono un margine di sicurezza fino al livello di barriera. Questi prodotti possono essere rimborsati anticipatamente in modo automatico al 100.00% del nominale a seconda del livello del sottostante in una qualsiasi delle date di osservazione per autocall.",
    "AutocallableMultiBarrierReverseConvertibleName": "LUKB Autocallable Multi Barrier Reverse Convertible",
    "AutocallableMultiBarrierReverseConvertibleCategory": "Categoria SSPA: 1230 - Ottimizzazione del rendimento",
    "AutocallableMultiBarrierReverseConvertibleDescription": "LUKB Autocallable Multi Barrier Reverse Convertibles offrono agli Investitori l’opportunità di approfittare dei movimenti laterali o superiori al livello di barriera dei sottostanti. Questi prodotti garantiscono il pagamento di una cedola e offrono un margine di sicurezza fino al livello di barriera, applicato al sottostante con la performance più debole. Questi prodotti possono essere rimborsati anticipatamente in modo automatico al 100.00% del nominale a seconda del livello del sottostante con la performance più debole in una qualsiasi delle date di osservazione per autocall.",
    "SoftCallableBarrierReverseConvertibleName": "LUKB Softcallable Barrier Reverse Convertible",
    "SoftCallableBarrierReverseConvertibleCategory": "Categoria SSPA: 1230 - Ottimizzazione del rendimento",
    "SoftCallableBarrierReverseConvertibleDescription": "LUKB Softcallable Barrier Reverse Convertibles offrono agli Investitori l’opportunità di approfittare dei movimenti laterali o superiori al livello di barriera del sottostante. Questi prodotti garantiscono il pagamento di una cedola e offrono un margine di sicurezza fino al livello di barriera. L’Emittente ha il diritto di rimborsare anticipatamente tutti i prodotti al 100.00% del nominale.",
    "SoftCallableMultiBarrierReverseConvertibleName": "LUKB Softcallable Multi Barrier Reverse Convertible",
    "SoftCallableMultiBarrierReverseConvertibleCategory": "Categoria SSPA: 1230 - Ottimizzazione del rendimento",
    "SoftCallableMultiBarrierReverseConvertibleDescription": "LUKB Softcallable Multi Barrier Reverse Convertibles offrono agli Investitori l’opportunità di approfittare dei movimenti laterali o superiori al livello di barriera dei sottostanti. Questi prodotti garantiscono il pagamento di una cedola e offrono un margine di sicurezza fino al livello di barriera, applicato al sottostante con la performance più debole. L’Emittente ha il diritto di rimborsare anticipatamente tutti i prodotti al 100.00% del nominale.",
    "ReverseConvertibleName": "LUKB Reverse Convertible",
    "ReverseConvertibleCategory": "Categoria SSPA: 1220 - Ottimizzazione del rendimento",
    "ReverseConvertibleDescription": "LUKB Reverse Convertibles offrono agli Investitori l’opportunità di approfittare dei movimenti laterali o superiori al prezzo d’esercizio del sottostante. Questi prodotti garantiscono il pagamento di una cedola e offrono un margine di sicurezza fino al prezzo d’esercizio. Questi prodotti non prevedono alcun rimborso anticipato.",
    "MultiReverseConvertibleName": "LUKB Multi Reverse Convertible",
    "MultiReverseConvertibleCategory": "Categoria SSPA: 1220 - Ottimizzazione del rendimento",
    "MultiReverseConvertibleDescription": "LUKB Multi Reverse Convertibles offrono agli Investitori l’opportunità di approfittare dei movimenti laterali o superiori al prezzo d’esercizio dei sottostanti. Questi prodotti garantiscono il pagamento di una cedola e offrono un margine di sicurezza fino al prezzo d’esercizio, applicato al sottostante con la performance più debole. Questi prodotti non prevedono alcun rimborso anticipato.",
    "AutocallableMultiReverseConvertibleName": "LUKB Autocallable Multi Reverse Convertible",
    "AutocallableMultiReverseConvertibleCategory": "Categoria SSPA: 1220 - Ottimizzazione del rendimento",
    "AutocallableMultiReverseConvertibleDescription": "LUKB Autocallable Multi Reverse Convertibles offrono agli Investitori l’opportunità di approfittare dei movimenti laterali o superiori al prezzo d’esercizio dei sottostanti. Questi prodotti garantiscono il pagamento di una cedola e offrono un margine di sicurezza fino al prezzo d’esercizio, applicato al sottostante con la performance più debole. Questi prodotti possono essere rimborsati anticipatamente in modo automatico al 100.00% del nominale a seconda del livello del sottostante con la performance più debole in una qualsiasi delle date di osservazione per autocall.",
    "SoftCallableReverseConvertibleName": "LUKB Softcallable Reverse Convertible",
    "SoftCallableReverseConvertibleCategory": "Categoria SSPA: 1220 - Ottimizzazione del rendimento",
    "SoftCallableReverseConvertibleDescription": "LUKB Softcallable Reverse Convertibles offrono agli Investitori l’opportunità di approfittare dei movimenti laterali o superiori al prezzo d’esercizio del sottostante. Questi prodotti garantiscono il pagamento di una cedola e offrono un margine di sicurezza fino al prezzo d’esercizio. L’Emittente ha il diritto di rimborsare anticipatamente tutti i prodotti al 100.00% del nominale.",
    "SoftCallableMultiReverseConvertibleName": "LUKB Softcallable Multi Reverse Convertible",
    "SoftCallableMultiReverseConvertibleCategory": "Categoria SSPA: 1220 - Ottimizzazione del rendimento",
    "SoftCallableMultiReverseConvertibleDescription": "LUKB Softcallable Multi Reverse Convertibles offrono agli Investitori l’opportunità di approfittare dei movimenti laterali o superiori al prezzo d’esercizio dei sottostanti. Questi prodotti garantiscono il pagamento di una cedola e offrono un margine di sicurezza fino al prezzo d’esercizio, applicato al sottostante con la performance più debole. L’Emittente ha il diritto di rimborsare anticipatamente tutti i prodotti al 100.00% del nominale.",
    "CapitalProtectionWithParticipationName": "LUKB Certificati a Protezione del Capitale con Partecipazione",
    "CapitalProtectionWithParticipationCategory": "Categoria SSPA: 1100 - Protezione del capitale",
    "CapitalProtectionWithParticipationDescription": "LUKB Certificati a Protezione del Capitale con Partecipazione consentono all’Investitore di partecipare a un rialzo del sottostante beneficiando anche di una protezione del capitale parziale alla scadenza. Questi prodotti corrispondono un ammontare di rimborso minimo pari al livello di protezione del capitale più una partecipazione illimitata alla performance del sottostante al di sopra del prezzo d’esercizio, entrambi alla data di rimborso.",
    "CapitalProtectionWithParticipationWorstOfName": "LUKB Certificati a Protezione del Capitale con Partecipazione al worst of",
    "CapitalProtectionWithParticipationWorstOfCategory": "Categoria SSPA: 1100 - Protezione del capitale",
    "CapitalProtectionWithParticipationWorstOfDescription": "LUKB Certificati a Protezione del Capitale con Partecipazione al worst of consentono all’Investitore di partecipare a un rialzo dei sottostanti beneficiando anche di una protezione del capitale parziale alla scadenza. Questi prodotti corrispondono un ammontare di rimborso minimo pari al livello di protezione del capitale più una partecipazione illimitata alla performance del sottostante con la performance più debole al di sopra del suo prezzo d’esercizio, entrambi alla data di rimborso.",
    "CapitalProtectionWithParticipationCappedWorstOf": "Certificato a Protezione del Capitale con Partecipazione limitata al worst of",
    "CapitalProtectionWithConditionalMemoryCouponWorstOf": "Certificato a Protezione del Capitale con Cedola Condizionale Memory",
    "range_0": "< 10.00%",
    "range_1": "10.00% - 20.00%",
    "range_2": "20.00% - 30.00%",
    "range_3": "30.00% - 40.00%",
    "range_4": "40.00% - 50.00%",
    "range_5": "> 50.00%",
    "php_date_iso8601_format": "Y-m-d\\TH:i:sP",
    "pdf_report_disclaimer": "Le informazioni contenute nel presente documento hanno carattere esclusivamente informativo e non costituiscono un'offerta. Sebbene siano state elaborate adottando la dovuta diligenza e cura, la Luzerner Kantonalbank non rilascia alcuna garanzia e non si assume alcuna responsabilità in merito alla loro correttezza.",
    "once_after": "una volta dopo",
    "observationDaysList": "Date di osservazione cedola condizionale",
    "conditionalCouponPa": "Cedola Condizionale p.a.",
    "triggerObservationEndDate": "Data di osservazione per la cedola condizionale",
    "triggerLevelRefCurPercent": "Trigger Level della cedola",
    "paymentDate": "Date di pagamento cedola condizionale",
    "paymentAmount": "Ammontare cedole condizionali",
    "paidAmount": "",
    "paid": "Pagato",
    "paid_not": "Non pagato",
    "maturity": "A scadenza",
    "key_information_document": "KID",
    "kid_dl": "Scaricare il KID",
    "dividendHandling": "Gestione dei dividendi",
    "Participation": "Partecipazione",
    "distributing": "Compensazione in contanti",
    "reinvesting": "Reinvestiti",
    "discounting": "Scontati",
    "ActivelyManagedTrackerCertificate": "Certificato Tracker Gestito Attivamente",
    "ActivelyManagedTrackerCertificateName": "LUKB Certificato Tracker Gestito Attivamente",
    "ActivelyManagedTrackerCertificateCategory": "Categoria SSPA: 1300 - A partecipazione",
    "basketComponent": "Sottostante",
    "weight": "Ponderazione iniziale",
    "basketCompPerf2RefPriceRelative": "Performance dall'emissione",
    "coupon_has_not_triggered": "Cedola non originata",
    "coupon_has_triggered": "Cedola originata",
    "lifespan_text_callable": "Durata max.",
    "lifespan_text_non_callable": "Durata",
    "newsletter_title": "Abbonarsi alla newsletter",
    "newsletter_subtitle": "Si iscriva alla nostra Newsletter settimanale o alle nostre idee d'investimento per rimanere sempre aggiornato.",
    "newsletter_please_choose_type": "La preghiamo di selezionare la Newsletter desiderata:",
    "newsletter_example": "Esempio",
    "newsletter_products_in_subscription": "Prodotti in sottoscrizione (settimanale)",
    "newsletter_products_in_subscription_link": "https://nbild.lukb.ch/gallery/K7FBhDT0IgPnAbNTI6/Demo_NL_KSP_IT.html",
    "newsletter_secondary_market_opportunities": "Opportunità sul mercato secondario (settimanale)",
    "newsletter_secondary_market_opportunities_link": "https://nbild.lukb.ch/gallery/K7FBhDT0IgPnAbNTI6/Demo_NL_KSP_SEK_IT.html",
    "newsletter_form_field_gotoUrl": "https://prodottistrutturati.lukb.ch/servizi/newsletter-autentificazione",
    "newsletter_form_field_gotoConfirmationUrl": "https://prodottistrutturati.lukb.ch/servizi/newsletter-conferma",
    "newsletter_investment_ideas": "Idea d’investimento mensile",
    "newsletter_languages": "Lingua della Newsletter:",
    "newsletter_languages_DE": "Tedesco",
    "newsletter_languages_EN": "Inglese",
    "newsletter_languages_IT": "Italiano",
    "newsletter_languages_FR": "Francese",
    "newsletter_please_choose_contact": "La preghiamo di inserire i suoi dati di contatto:",
    "newsletter_contact_title": "Appellativo*",
    "newsletter_contact_mr": "Signor",
    "newsletter_contact_mrs": "Signora",
    "newsletter_contact_firstname": "Nome*",
    "newsletter_contact_lastname": "Cognome*",
    "newsletter_contact_company": "Azienda",
    "newsletter_contact_company_name": "Società",
    "newsletter_contact_email": "E-Mail*",
    "newsletter_privacy": "Confermo di accettare i <a target=\"_blank\" href=\"/note-legali#condizioni-di-utilizzo-copyright\">termini di utilizzo</a> e l'<a target=\"_blank\" href=\"/note-legali#protezione-e-sicurezza-dei-dati\">informativa sulla privacy</a>.",
    "newsletter_required": "Tutti i campi contrassegnati con * sono obbligatori. La trasmissione dei vostri dati è criptata.",
    "newsletter_submit": "Abbonarsi",
    "newsletter_error_type": "La preghiamo di selezionare almeno una Newsletter",
    "newsletter_error_salutation": "Per favore selezioni un appellativo.",
    "newsletter_error_firstname": "Per favore inserisca un nome.",
    "newsletter_error_lastname": "Per favore inserisca un cognome.",
    "newsletter_error_email": "Per favore inserisca un indirizzo e-mail valido.",
    "newsletter_error_privacy": "La preghiamo di confermare le disposizioni relative alla protezione dei dati.",
    "newsletter_form_field_gotoErrorUrl": "https://prodottistrutturati.lukb.ch/servizi/mail-error",
    "quoting_method_value": "in unità",
    "basket": "Basket",
    "am": "a",
    "AutocallableMultiBarrierReverseConvertibleWithConditionalMemoryCouponName": "LUKB Autocallable Multi Barrier Reverse Convertible con Cedola Condizionale Memory",
    "AutocallableMultiBarrierReverseConvertibleWithConditionalMemoryCouponCategory": "Categoria SSPA: 1260 - Ottimizzazione del rendimento",
    "CapitalProtectionWithConditionalMemoryCouponWorstOfName": "LUKB Certificato a Protezione del Capitale con Cedola Condizionale Memory",
    "CapitalProtectionWithConditionalMemoryCouponWorstOfCategory": "Categoria SSPA: 1140 - Protezione del Capitale",
    "CapitalProtectionWithConditionalCouponWorstOfName": "LUKB Certificato a Protezione del Capitale con Cedola Condizionale",
    "CapitalProtectionWithConditionalCouponWorstOfCategory": "Categoria SSPA: 1140 - Protezione del Capitale",
    "CapitalProtectionWithParticipationCappedName": "LUKB Certificato a Protezione del Capitale con Partecipazione limitata",
    "CapitalProtectionWithParticipationCappedCategory": "Categoria SSPA: 1100 - Protezione del Capitale",
    "CapitalProtectionWithParticipationCappedWorstOfName": "LUKB Certificato a Protezione del Capitale con Partecipazione limitata al worst of",
    "CapitalProtectionWithParticipationCappedWorstOfCategory": "Categoria SSPA: 1100 - Protezione del Capitale",
    "CapitalProtectionWithParticipationSumBasketName": "LUKB Certificato a Protezione del Capitale con Partecipazione al Basket",
    "CapitalProtectionWithParticipationSumBasketCategory": "Categoria SSPA: 1100 - Protezione del Capitale",
    "CapitalProtectionWithParticipationCappedSumBasketName": "LUKB Certificato a Protezione del Capitale con Partecipazione limitata al Basket",
    "CapitalProtectionWithParticipationCappedSumBasketCategory": "Categoria SSPA: 1100 - Protezione del Capitale",
    "Payment_date_dividend": "Data di pagamento",
    "absolute_payment": "Importo del pagamento di compensazione",
    "dividends": "Pagamenti di compensazione",
    "performanceSinceIssuePercentWithoutDividends": "Andamento dall'emissione"
};
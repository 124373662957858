// instrument.service.js
(function() {
    'use strict';

    angular
        .module('app.services')
        .factory('InstrumentService', InstrumentService);

    // Inject dependencies.
    InstrumentService.$inject = [
        'HttpWrapper',
        '$q',
        '$http',
        'ApiService',
        'KeyInformationDocumentSettings'
    ];

    // Init InstrumentService.
    function InstrumentService(HttpWrapper, $q, $http, ApiService, KeyInformationDocumentSettings) {
        return {


            /**
             * Get instrument data.
             * @param {string} idType - Identifier Type.
             * @param {string} idValue - Identifier Value.
             */
            get: function(idType, idValue) {
                var deferred = $q.defer();

                HttpWrapper({
                        method: 'GET',
                        url: '/product/detail/' + idType + '/' + idValue,
                        refreshProfiler: true
                    },
                    function(response) {
                        deferred.resolve(response);
                    },
                    function(rejection) {
                        deferred.reject(rejection);
                    }
                );

                return deferred.promise;

            },


            /**
             * Get instrument groups details
             * @param {string} url - Url to get groups.
             */
            getGroups: function(url) {
                var deferred = $q.defer();

                HttpWrapper({
                        method: 'GET',
                        refreshProfiler: true,
                        url: url
                    },
                    function(response) {
                        deferred.resolve(response);
                    },
                    function(rejection) {
                        deferred.reject(rejection);
                    });

                return deferred.promise;
            },


            /**
             * Get products (instruments list) for a group
             * @param {string} group - Name of the group.
             * @param {string} url - Url to get products.
             * @param {string} queryParams - Query params. For example: product filter query.
             */
            getProducts: function(group, baseURL, queryParams) {
                var deferred = $q.defer(),
                    url = baseURL + '/list';

                // Handling multiple groups (yield matrix 3rd tab)
                if (group.id && typeof group.id === 'object' && group.id.length > 1) {
                    url += '?';
                    for (var i = 0; i < group.id.length; i++) {
                        url += (i > 0 ? '&' : '') + 'group[]=' + group.id[i];
                    }

                } else {
                    url += '?group=' + group.id;
                }


                // Add query params
                if (queryParams && queryParams.length > 0) {
                    url += '&' + queryParams;
                }

                // Add query params
                if (group.paging) {
                    url += '&page=' + group.paging.page + '&rowsPerPage=' + group.paging.rowsPerPage;
                }

                HttpWrapper({
                        method: 'GET',
                        url: url,
                        refreshProfiler: true
                    },
                    function(response) {
                        response.groupId = group.id;
                        deferred.resolve(response);
                    },
                    function(rejection) {
                        deferred.reject(rejection);
                    }
                );

                return deferred.promise;
            },

            /**
             * Get instrument documents.
             * @param {string} sin - Identifier Value.
             */
            getDocuments: function(sin) {
                var deferred = $q.defer();

                HttpWrapper({
                        method: 'GET',
                        url: '/product/document/sin/' + sin,
                        refreshProfiler: true
                    },
                    function(response) {
                        deferred.resolve(response);
                    },
                    function(rejection) {
                        deferred.reject(rejection);
                    }
                );

                return deferred.promise;

            },


            /**
             * Generate and get instrument KID documents.
             * @param {string} isin - Identifier Value.
             */
            getKidDocuments: function(isin) {
                var data = {};
                for (var lang in KeyInformationDocumentSettings.locales){
                    data[lang] = KeyInformationDocumentSettings.rootURL + 'isin=' + isin + '&documentLocales=' + KeyInformationDocumentSettings.locales[lang] + '&documentType=KIDforPRIIP';
                }

                return data;
            },

            /**
             * Get search results
             * @param queryParams
             */
            getSearchResults: function(queryParams) {
                var deferred = $q.defer(),
                    url = ApiService.getSearch().url;

                $http({
                    method: 'GET',
                    url: url,
                    params: queryParams
                }).then(function(response) {
                    deferred.resolve(response);
                }, function(rejection) {
                    deferred.reject(rejection);
                });

                return deferred.promise;
            },

            /**
             * Create next day UTC date
             * @param initialFixingDate
             * @returns {Date}
             */
            createNextDayDate: function(initialFixingDate) {
                var nextDay = new Date();
                nextDay.setUTCFullYear(initialFixingDate.getUTCFullYear());
                nextDay.setUTCMonth(initialFixingDate.getUTCMonth());
                nextDay.setUTCDate(initialFixingDate.getUTCDate() + 1);
                nextDay.setUTCHours(7);
                nextDay.setUTCMinutes(30);
                nextDay.setUTCSeconds(0);
                nextDay.setUTCMilliseconds(0);
                return nextDay;
            }
        };
    }
})();